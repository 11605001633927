@import 'mixin';

.modal{
    .modal-content{
        background-color: $yellow;
    }
    &.logIn{
        .modal-body{
            padding: 0 85px 40px;
            width: 100%;
            @media screen and (max-width: 480px) {
                padding: 0 15px 20px;
            }
            .enter-form{
                .enter-form__title{
                    @include h3;
                    margin-bottom: 1.5rem;
                    text-align: center;
                }
                label {
                    
                    input {
                        width: 100%;
                        text-align: center;
                        padding: 0;
                    }
                }
            }
            .social-enter{
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid #000;
                p{
                    font-family: MontserratBold, sans-serif;
                    padding: 0 30px;
                    margin: 0 0 20px;
                    text-align: center;
                    font-size: 22px;
                    @media screen and (max-width: 480px) {
                        padding: 0 15px;
                        font-size: 16px;
                    }
                }
                &__link-block{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    a {
                        width: 44px;
                        height: 44px;
                        border-radius: 5px;
                        display: block;
                        &:first-child {
                            margin-right: 15px;
                        }
                    }
                    .social-enter{
                        &__google {
                            background: url(/img/png/google-plus-logo-on-black-background.png) 50% no-repeat;
                        }
                        &__facebook {
                            background: url(/img/png/facebook-app-logo.png) 50% no-repeat;
                        }
                    }
                }
            }
            .enter-form{
                &__password {
                    position: relative;
                    .showPass {
                        height: 20px;
                        width: 30px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        background: url(/img/svg/eye-01.svg) 50% no-repeat;
                    }
                    .showPass{
                        &.show{
                            background: url(/img/svg/eye-01-blk.svg) 50% no-repeat;
                        }
                    }
                }
                .lostPassword {
                    width: 100%;
                    text-align: left;
                    font-size: 14px;
                    color: #000;
                    text-decoration: underline;
                    margin-bottom: 2rem;
                }
            }
            .sendUs{
                .first_name{
                    width: 49%;
                    @media screen and (max-width: 580px) {
                        width: 100%;
                    }
                }
                .phone{
                    width: 49%;
                    @media screen and (max-width: 580px) {
                        width: 100%;
                    }
                }
            }
            .enter-form {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
            .btn_main{
                height: 60px;
                border-radius: 30px;
                font-size: 20px;
                width: 100%;
                margin: 5px 0;
            }
        }
    }

    &.awser{
        .modal-body{
            padding: 0 50px 40px;
            width: 100%;
            @media screen and (max-width: 480px) {
                padding: 0 15px 20px;
            }
            .enter-form{
                .enter-form__title{
                    @include h3;
                    margin-bottom: 1.5rem;
                    text-align: center;
                }
            }
            .enter-form {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
            
        }
        .modal-subject{
            padding: 0px 10px;
        }
    }
}
.modal-header{
    border-bottom: none;
    .close{
        background: url(/img/svg/close-cross.svg) 50% / cover;
        height: 15px;
        width: 15px;
        margin-top: 0;
        margin-right: 0;
        padding: 0;
        span{
            opacity: 0;
        }
    }
    .modal-title{
        font-weight: 700;
        width: 100%;
        text-align: center;
    }
}
.modal_registration{
    .modal-content{
        width: 100%;
    }
}
#orderForm{
    .modal-dialog{
        max-width: 910px; 
    }
}
.modal_order_constructor{
    .modal-dialog{
    max-width: 910px;
    }
}
#add-product-basket, #group-add-window{
    .modal-header{
        text-align: center;
        color: #000;
        font-size: 22px;
        font-weight: 600;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.modal_registration {
    height: 100vh;
    .modal-dialog {
        max-height: 90%;
        height: 90%;
    }
}
.modal-subject{
    text-align: center;
    padding: 30px 10px;
}